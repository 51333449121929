<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isUpdateCourtSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-court-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Update Courts</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <h5 class="text-primary">Supreme Court</h5>
          <!-- User Supreme Court -->
          <b-form-group
            label="Select Supreme Court"
            label-for="user-supreme-court"
          >
            <v-select
              v-model="userData.supreme_court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="supremeCourtOption"
              placeholder="Select Supreme Court"
              :clearable="true"
              :reduce="(val) => val.value"
              input-id="user-supreme-court"
            />
          </b-form-group>
          <!-- SC Adv. Id/AOR/Full Name -->
          <b-form-group
            label="Adv. Id/AOR/Full Name (Supreme Court)"
            label-for="scAOR"
          >
            <b-form-input
              id="scAOR"
              placeholder="Adv. SC AOR"
              v-model="userData.sc_advocate_key_id"
              trim
            />
          </b-form-group>

          <!-- SC Adv. Id/AOR/Full Name -->
          <b-form-group
            label="Filter By Name (Supreme Court)"
            label-for="scFilterByName"
          >
            <v-select
              v-model="userData.sc_filter_by_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['Yes', 'No']"
              placeholder="Filter By Name"
              :clearable="true"
              :reduce="(val) => val"
              input-id="scFilterByName"
            />
          </b-form-group>

          <h5 class="text-primary mt-1">High Court</h5>
          <!-- User Court -->
          <b-form-group label="Select High Court" label-for="user-court">
            <v-select
              v-model="userData.court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="courtOption"
              placeholder="Select High Court"
              :clearable="true"
              :reduce="(val) => val.value"
              input-id="user-court"
            />
          </b-form-group>

          <!-- Adv. Id/AOR/Full Name -->
          <b-form-group label="Adv. Id/AOR/Full Name" label-for="advId">
            <b-form-input
              id="advId"
              placeholder="Adv. High Court Id"
              v-model="userData.advocate_key_id"
              trim
            />
          </b-form-group>

          <h5 class="text-primary mt-1">District Court</h5>
          <!-- District Court Select -->
          <b-form-group
            label="Select District Court"
            label-for="user-district-court"
          >
            <v-select
              v-model="userData.district_court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="districtOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select District Court"
              input-id="user-district-court"
            />
          </b-form-group>

          <!-- Bar Code/Full Name (For District Court) -->
          <b-form-group
            label="Bar Code/Full Name (District Court)"
            label-for="district_advocate_key_id"
          >
            <b-form-input
              id="district_advocate_key_id"
              placeholder="Adv. District Id"
              v-model="userData.district_advocate_key_id"
              trim
            />
          </b-form-group>

          <h5 class="text-danger mt-1">Removed Tribunals Court</h5>

          <!-- Tribunals Court -->
          <b-form-group label="Select Tribunal" label-for="user-tribunal-court">
            <v-select
              v-model="userData.tribunal_court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select Tribunal"
              input-id="user-tribunal-court"
            />
          </b-form-group>

          <!-- Advocate Name (For Tribunal Court) -->
          <b-form-group
            label="Advocate Name (Tribunal)"
            class="text-danger"
            label-for="tribunal_advocate_key_id"
          >
            <b-form-input
              id="tribunal_advocate_key_id"
              placeholder="Adv. Tribunal Id"
              v-model="userData.tribunal_advocate_key_id"
              trim
            />
          </b-form-group>

          <h5 class="text-primary mt-1">Tribunals Court</h5>

          <!-- CAT Court -->
          <b-form-group label="Select CAT" label-for="user-cat-court">
            <v-select
              v-model="userData.tribunals.cat.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select CAT"
              input-id="user-cat-court"
            />
          </b-form-group>

          <!-- Advocate Name (For CAT Court) -->
          <b-form-group
            label="Advocate Name (CAT)"
            label-for="cat_advocate_key_id"
          >
            <b-form-input
              id="cat_advocate_key_id"
              placeholder="Adv. CAT Id"
              v-model="userData.tribunals.cat.aor"
              trim
            />
          </b-form-group>

          <!-- Confonet Court -->
          <b-form-group label="Select Confonet" label-for="user-confonet-court">
            <v-select
              v-model="userData.tribunals.confonet.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select Confonet"
              input-id="user-confonet-court"
            />
          </b-form-group>

          <!-- Advocate Name (For Confonet Court) -->
          <b-form-group
            label="Advocate Name (Confonet)"
            label-for="confonet_advocate_key_id"
          >
            <b-form-input
              id="confonet_advocate_key_id"
              placeholder="Adv. CAT Id"
              v-model="userData.tribunals.confonet.aor"
              trim
            />
          </b-form-group>

          <!-- DRT Court -->
          <b-form-group label="Select DRT" label-for="user-drt-court">
            <v-select
              v-model="userData.tribunals.drt.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select DRT"
              input-id="user-drt-court"
            />
          </b-form-group>

          <!-- Advocate Name (For DRT Court) -->
          <b-form-group
            label="Advocate Name (DRT)"
            label-for="drt_advocate_key_id"
          >
            <b-form-input
              id="drt_advocate_key_id"
              placeholder="Adv. DRT Id"
              v-model="userData.tribunals.drt.aor"
              trim
            />
          </b-form-group>

          <!-- RERA Court -->
          <b-form-group label="Select RERA" label-for="user-rera-court">
            <v-select
              v-model="userData.tribunals.rera.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select RERA"
              input-id="user-rera-court"
            />
          </b-form-group>

          <!-- Advocate Name (For RERA Court) -->
          <b-form-group
            label="Advocate Name (RERA)"
            label-for="rera_advocate_key_id"
          >
            <b-form-input
              id="rera_advocate_key_id"
              placeholder="Adv. RERA Id"
              v-model="userData.tribunals.rera.aor"
              trim
            />
          </b-form-group>

          <!-- Reat Court -->
          <b-form-group label="Select REAT" label-for="user-reat-court">
            <v-select
              v-model="userData.tribunals.reat.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select REAT"
              input-id="user-reat-court"
            />
          </b-form-group>

          <!-- Advocate Name (For REAT Court) -->
          <b-form-group
            label="Advocate Name (REAT)"
            label-for="reat_advocate_key_id"
          >
            <b-form-input
              id="reat_advocate_key_id"
              placeholder="Adv. REAT Id"
              v-model="userData.tribunals.reat.aor"
              trim
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BFormTags,
  BInputGroupAppend
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isUpdateCourtSidebarActive',
    event: 'update:is-update-court-sidebar-active'
  },
  props: {
    isUpdateCourtSidebarActive: {
      type: Boolean,
      required: true
    },
    supremeCourtOptions: {
      type: Array,
      required: true
    },
    courtOptions: {
      type: Array,
      required: true
    },
    districtOptions: {
      type: Array,
      required: true
    },
    tribunalOptions: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required
    }
  },
  computed: {},
  methods: {},
  setup(props, context) {
    console.log('Adv Names', props.data.name_filters)
    var courtOption = props.courtOptions
    var supremeCourtOption = props.supremeCourtOptions
    var districtOption = props.districtOptions
    var tribunalOption = props.tribunalOptions
    const blankUserData = {
      user_id: props.data._id,
      fullName: props.data.fullName,
      court_id: props.data.court_id,
      supreme_court_id: props.data.supreme_court_id,
      sc_filter_by_name: props.data.sc_filter_by_name,
      district_court_id: props.data.district_court_id,
      tribunal_court_id: props.data.tribunal_court_id,
      advocate_key_id: props.data.advocate_key_id,
      district_advocate_key_id: props.data.district_advocate_key_id,
      tribunal_advocate_key_id: props.data.tribunal_advocate_key_id,
      sc_advocate_key_id: props.data.sc_advocate_key_id,
      address: props.data.address,
      tribunals:
        Object.keys(props.data).includes('tribunals') &&
        props.data.tribunals !== null
          ? props.data.tribunals
          : {
              cat: {
                id: '',
                aor: ''
              },
              confonet: {
                id: '',
                aor: ''
              },
              drt: {
                id: '',
                aor: ''
              },
              rera: {
                id: '',
                aor: ''
              },
              reat: {
                id: '',
                aor: ''
              }
            },
      test_user: true,
      mobile: props.data.mobile,
      name_filters: props.data.name_filters
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store
        .dispatch('userStore/updateUser', { payload: userData.value })
        .then((response) => {
          if (response != null) {
            context.emit('refetch-data')
            context.emit('update:is-update-court-sidebar-active', false)
          }
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      tribunalOption,
      courtOption,
      supremeCourtOption,
      districtOption,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
